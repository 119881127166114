@keyframes skeleton-keyframes {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(200%);
  }
}
.skeleton-animation {
  animation: skeleton-keyframes 1200ms ease-in-out infinite;
}
